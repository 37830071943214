import React, {
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
  useRef,
} from 'react';
import { Form } from '@unform/web';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { Link, useHistory, useParams } from 'react-router-dom';
import { FormHandles } from '@unform/core';

import api from '~/services/api';

import {
  Container,
  VideoInfo,
  LessonInfo,
  Actions,
  Options,
  Content,
  Img,
  VideoPreview,
} from './styles';
import Input from '~/components/Input';
import InputMask from '~/components/InputMask';
import Textarea from '~/components/Textarea';
import Player from '~/components/Player';
import Downloads, { downloadTempProps } from '~/components/Downloads';
import Resources, { resourceTempProps } from '~/components/Resources';
import Quizzes, { IQuestion } from '~/components/Quizzes';
import CoursesActionTitle from '~/components/CoursesActionTitle';

import videoThumb from '~/assets/icons/video_thumb.svg';
import imgThumb from '~/assets/icons/img_thumb.svg';
import user from '~/assets/icons/user-icon.svg';
import whatsapp from '~/assets/icons/whatsapp-icon.svg';
import mail from '~/assets/icons/mail-icon.svg';
import skype from '~/assets/icons/skype-icon.svg';
import comment from '~/assets/icons/comment-icon.svg';
import getValidationErros from '~/utils/getValidationsErrors';

interface IParams {
  slug: string;
}
interface lessonFormData {
  title: string;
  description: string;
  slug: string;
  page_title: string;
  meta_description: string;
}

const CoursesRegister: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const params = useParams<IParams>();
  const [courseId, setCourseId] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [thumbnailSelected, setThumbnailSelected] = useState<File | null>(null);
  const [video, setVideoPhoto] = useState('');
  const [videoSelected, setVideoSelected] = useState<File | null>(null);
  const [url, setUrl] = useState('');
  const [watchFull, setWatchFull] = useState('no');
  const [answerQuiz, setAnswerQuiz] = useState('no');
  const [coachButton, setCoachButton] = useState('no');
  const [coachUnlock, setCoachUnlock] = useState('no');
  const [allowAbout, setAllowAbout] = useState('yes');
  const [allowNextStep, setAllowNextStep] = useState('yes');
  const [allowSpecialNote, setAllowSpecialNote] = useState('yes');
  const [allowDiscussion, setAllowDiscussion] = useState('no');
  const [allowDownloads, setAllowDownloads] = useState('no');
  const [allowResources, setAllowResources] = useState('no');
  const [optionSelected, setOptionSelected] = useState('about');
  const [coacheSpace, setCoacheSpace] = useState(false);
  const [about, setAbout] = useState('');
  const [nextStep, setNextStep] = useState('');
  const [specialNote, setSpecialNote] = useState('');
  const [downloads, setDownloads] = useState<downloadTempProps[]>([]);
  const [resources, setResources] = useState<resourceTempProps[]>([]);
  const [aboutError, setAboutError] = useState('');
  const [nextStepError, setNextStepError] = useState('');
  const [specialNoteError, setSpecialNoteError] = useState('');
  const [downloadsError, setDownloadsError] = useState('');
  const [resourcesError, setResourcesError] = useState('');
  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [answersHasError, setAnswersHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [videoDuration, setVideoDuration] = useState('');
  const [status, setStatus] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);

    api.get(`courses/${params.slug}`).then((response) => {
      setCourseId(response.data.id);
    });
  }, [params.slug]);

  const handleBlurDuration = useCallback((e) => {
    const duration = e.target.value;
    const [hours, minutes, seconds] = duration.split(':');

    let newHours = hours;
    let newMinutes = minutes;
    let newSeconds = seconds;

    if (newHours) {
      if (newHours.length < 2) {
        newHours += '0';
      }
    } else {
      newHours = '00';
    }

    if (newMinutes) {
      if (newMinutes > 59) {
        newMinutes = 59;
      } else if (newMinutes.length < 2) {
        newMinutes += '0';
      }
    } else {
      newMinutes = '00';
    }

    if (newSeconds) {
      if (newSeconds > 59) {
        newSeconds = 59;
      } else if (newSeconds.length < 2) {
        newSeconds += '0';
      }
    } else {
      newSeconds = '00';
    }

    setVideoDuration(`${newHours}:${newMinutes}:${newSeconds}`);
  }, []);

  useEffect(() => {
    if (video) {
      setTimeout(() => {
        const videoElement = document.querySelector(
          '#videoInfo video'
        ) as HTMLVideoElement;
        if (videoElement) {
          videoElement.onloadedmetadata = () => {
            const { duration } = videoElement;
            const hrs = `0${~~(duration / 3600)}`.slice(-2);
            const mins = `0${~~((duration % 3600) / 60)}`.slice(-2);
            const secs = `0${~~(duration % 60)}`.slice(-2);
            const durationData = `${hrs}:${mins}:${secs}`;
            setVideoDuration(durationData);
            const data = {
              target: {
                value: durationData,
              },
            };
            handleBlurDuration(data);
          };
        }
      }, 100);
    }
  }, [video, handleBlurDuration]);

  const handleSelectThumbnail = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const file = e.target.files[0];
        if (file) {
          setThumbnail(URL.createObjectURL(file));
          setThumbnailSelected(file);
        } else {
          setThumbnail('');
          setThumbnailSelected(null);
        }
      }
    },
    []
  );

  const handleSelectVideo = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file) {
        setVideoPhoto(URL.createObjectURL(file));
        setVideoSelected(file);
      } else {
        setVideoPhoto('');
        setVideoSelected(null);
        setVideoDuration('');
      }
    }
  }, []);

  const handleRemoveVideo = useCallback(() => {
    setVideoPhoto('');
    setVideoSelected(null);
    setVideoDuration('');
  }, []);

  const handleChangeDuration = useCallback((e) => {
    const duration = e.target.value;
    const [hours, minutes, seconds] = duration.split(':');
    let newMinutes = minutes;
    let newSeconds = seconds;

    if (newMinutes > 59) {
      newMinutes = 59;
    }

    if (newSeconds > 59) {
      newSeconds = 59;
    }

    setVideoDuration(`${hours}:${newMinutes}:${newSeconds}`);
  }, []);

  const handleChangeTitle = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setUrl(
      e.target.value
        .replace(/[àáâãäå]/g, 'a')
        .replace(/æ/g, 'ae')
        .replace(/ç/g, 'c')
        .replace(/[èéêë]/g, 'e')
        .replace(/[ìíîï]/g, 'i')
        .replace(/ñ/g, 'n')
        .replace(/[òóôõö]/g, 'o')
        .replace(/œ/g, 'oe')
        .replace(/[ùúûü]/g, 'u')
        .replace(/[ýÿ]/g, 'y')
        .replace(/[^a-zA-Z0-9 -]/g, '')
        .replace(/ /g, '-')
        .toLowerCase()
    );
  }, []);

  const handleChangeSlug = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setUrl(
      e.target.value
        .replace(/[àáâãäå]/g, 'a')
        .replace(/æ/g, 'ae')
        .replace(/ç/g, 'c')
        .replace(/[èéêë]/g, 'e')
        .replace(/[ìíîï]/g, 'i')
        .replace(/ñ/g, 'n')
        .replace(/[òóôõö]/g, 'o')
        .replace(/œ/g, 'oe')
        .replace(/[ùúûü]/g, 'u')
        .replace(/[ýÿ]/g, 'y')
        .replace(/[^a-zA-Z0-9 -]/g, '')
        .replace(/ /g, '-')
        .toLowerCase()
    );
  }, []);

  const changeWatchFull = useCallback((e) => {
    setWatchFull(e.target.value);
  }, []);

  const changeAnswerQuiz = useCallback((e) => {
    setAnswerQuiz(e.target.value);
  }, []);

  const changeCoachButton = useCallback((e) => {
    setCoachButton(e.target.value);
  }, []);

  const changeCoachUnlock = useCallback((e) => {
    setCoachUnlock(e.target.value);
  }, []);

  const changeAllowAbout = useCallback(
    (e) => {
      if (e.target.value === 'no' && optionSelected === 'about') {
        switch ('yes') {
          case allowNextStep:
            setOptionSelected('next-step');
            break;
          case allowSpecialNote:
            setOptionSelected('special-note');
            break;
          case allowDiscussion:
            setOptionSelected('discussion');
            break;
          case allowDownloads:
            setOptionSelected('downloads');
            break;
          case allowResources:
            setOptionSelected('resources');
            break;
          default:
            setOptionSelected('');
            break;
        }
      } else if (e.target.value === 'yes' && optionSelected === '') {
        setOptionSelected('about');
      }
      setAllowAbout(e.target.value);
    },
    [
      allowDiscussion,
      allowDownloads,
      allowNextStep,
      allowResources,
      allowSpecialNote,
      optionSelected,
    ]
  );

  const changeAllowNextStep = useCallback(
    (e) => {
      if (e.target.value === 'no' && optionSelected === 'next-step') {
        switch ('yes') {
          case allowAbout:
            setOptionSelected('about');
            break;
          case allowSpecialNote:
            setOptionSelected('special-note');
            break;
          case allowDiscussion:
            setOptionSelected('discussion');
            break;
          case allowDownloads:
            setOptionSelected('downloads');
            break;
          case allowResources:
            setOptionSelected('resources');
            break;
          default:
            setOptionSelected('');
            break;
        }
      } else if (e.target.value === 'yes' && optionSelected === '') {
        setOptionSelected('next-step');
      }
      setAllowNextStep(e.target.value);
    },
    [
      allowAbout,
      allowDiscussion,
      allowDownloads,
      allowResources,
      allowSpecialNote,
      optionSelected,
    ]
  );

  const changeAllowSpecialNote = useCallback(
    (e) => {
      if (e.target.value === 'no' && optionSelected === 'special-note') {
        switch ('yes') {
          case allowNextStep:
            setOptionSelected('next-step');
            break;
          case allowAbout:
            setOptionSelected('about');
            break;
          case allowDiscussion:
            setOptionSelected('discussion');
            break;
          case allowDownloads:
            setOptionSelected('downloads');
            break;
          case allowResources:
            setOptionSelected('resources');
            break;
          default:
            setOptionSelected('');
            break;
        }
      } else if (e.target.value === 'yes' && optionSelected === '') {
        setOptionSelected('special-note');
      }
      setAllowSpecialNote(e.target.value);
    },
    [
      allowAbout,
      allowDiscussion,
      allowDownloads,
      allowNextStep,
      allowResources,
      optionSelected,
    ]
  );

  const changeAllowDiscussion = useCallback(
    (e) => {
      if (e.target.value === 'no' && optionSelected === 'discussion') {
        switch ('yes') {
          case allowNextStep:
            setOptionSelected('next-step');
            break;
          case allowSpecialNote:
            setOptionSelected('special-note');
            break;
          case allowAbout:
            setOptionSelected('about');
            break;
          case allowDownloads:
            setOptionSelected('downloads');
            break;
          case allowResources:
            setOptionSelected('resources');
            break;
          default:
            setOptionSelected('');
            break;
        }
      } else if (e.target.value === 'yes' && optionSelected === '') {
        setOptionSelected('discussion');
      }
      setAllowDiscussion(e.target.value);
    },
    [
      allowAbout,
      allowDownloads,
      allowNextStep,
      allowResources,
      allowSpecialNote,
      optionSelected,
    ]
  );

  const changeAllowDownloads = useCallback(
    (e) => {
      if (e.target.value === 'no' && optionSelected === 'downloads') {
        switch ('yes') {
          case allowNextStep:
            setOptionSelected('next-step');
            break;
          case allowSpecialNote:
            setOptionSelected('special-note');
            break;
          case allowDiscussion:
            setOptionSelected('discussion');
            break;
          case allowAbout:
            setOptionSelected('about');
            break;
          case allowResources:
            setOptionSelected('resources');
            break;
          default:
            setOptionSelected('');
            break;
        }
      } else if (e.target.value === 'yes' && optionSelected === '') {
        setOptionSelected('downloads');
      }
      setAllowDownloads(e.target.value);
    },
    [
      allowAbout,
      allowDiscussion,
      allowNextStep,
      allowSpecialNote,
      optionSelected,
      allowResources,
    ]
  );

  const changeAllowResource = useCallback(
    (e) => {
      if (e.target.value === 'no' && optionSelected === 'resource') {
        switch ('yes') {
          case allowNextStep:
            setOptionSelected('next-step');
            break;
          case allowSpecialNote:
            setOptionSelected('special-note');
            break;
          case allowDiscussion:
            setOptionSelected('discussion');
            break;
          case allowAbout:
            setOptionSelected('about');
            break;
          case allowDownloads:
            setOptionSelected('downloads');
            break;
          default:
            setOptionSelected('');
            break;
        }
      } else if (e.target.value === 'yes' && optionSelected === '') {
        setOptionSelected('resources');
      }
      setAllowResources(e.target.value);
    },
    [
      allowAbout,
      allowDiscussion,
      allowNextStep,
      allowSpecialNote,
      optionSelected,
      allowDownloads,
    ]
  );

  const handleChangeAbout = useCallback((_, editor) => {
    setAbout(editor.getData());
  }, []);

  const handleChangeNextStep = useCallback((_, editor) => {
    setNextStep(editor.getData());
  }, []);

  const handleChangeSpecialNote = useCallback((_, editor) => {
    setSpecialNote(editor.getData());
  }, []);

  const handleChangeDownloads = useCallback((downloadsData) => {
    setDownloads(downloadsData);
  }, []);

  const handleChangeResources = useCallback((resourcesData) => {
    setResources(resourcesData);
  }, []);

  const handleChangeQuizzes = useCallback((questionsData) => {
    setQuestions(questionsData);
  }, []);

  const handleSubmit = useCallback(
    async (data: lessonFormData) => {
      setAboutError('');
      setNextStepError('');
      setSpecialNoteError('');
      setDownloadsError('');
      setResourcesError('');
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          title: Yup.string().required('Title is a required'),
          description: Yup.string().required('Description is a required'),
          duration: Yup.string(),
          slug: Yup.string().required('URL is a required'),
          page_title: Yup.string().required('Page Title is a required'),
          meta_description: Yup.string().required(
            'Meta Description is a required'
          ),
          thumbnail: Yup.string(),
          video: Yup.string(),
          quizzes: Yup.string().when('$exist', {
            is: (exist: boolean) => exist,
            then: Yup.string().required('Answers is a required'),
            otherwise: Yup.string(),
          }),
          about: Yup.string().when('$aboutValidation', {
            is: (aboutValidation: boolean) => aboutValidation,
            then: Yup.string().required('About is a required'),
            otherwise: Yup.string(),
          }),
          next_step: Yup.string().when('$nextStepValidation', {
            is: (nextStepValidation: boolean) => nextStepValidation,
            then: Yup.string().required('Next step is a required'),
            otherwise: Yup.string(),
          }),
          special_note: Yup.string().when('$specialNoteValidation', {
            is: (specialNoteValidation: boolean) => specialNoteValidation,
            then: Yup.string().required('Special Note is a required'),
            otherwise: Yup.string(),
          }),
          downloads: Yup.string().when('$downloadsValidation', {
            is: (downloadsValidation: boolean) => downloadsValidation,
            then: Yup.string().required('Downloads is a required'),
            otherwise: Yup.string(),
          }),
          resources: Yup.string().when('$resourcesValidation', {
            is: (resourcesValidation: boolean) => resourcesValidation,
            then: Yup.string().required('Resources is a required'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            exist: answerQuiz === 'yes',
            aboutValidation: allowAbout === 'yes' && about.length <= 0,
            nextStepValidation: allowNextStep === 'yes' && nextStep.length <= 0,
            specialNoteValidation:
              allowSpecialNote === 'yes' && specialNote.length <= 0,
            downloadsValidation:
              allowDownloads === 'yes' && downloads.length <= 0,
            resourcesValidation:
              allowResources === 'yes' && resources.length <= 0,
          },
        });

        let responseThumbnails;
        let responseVideo;

        if (thumbnailSelected) {
          setStatus('Uploading thumbnail...');
          const thumbnailData = new FormData();
          thumbnailData.append('thumbnail', thumbnailSelected as File);
          responseThumbnails = await api.post('thumbnails', thumbnailData);
        }

        if (videoSelected) {
          setStatus('Uploading video...');
          const videoData = new FormData();
          videoData.append('video', videoSelected as File);
          videoData.append('duration', videoDuration);
          responseVideo = await api.post('videos', videoData);
        }

        setStatus('Creating lesson...');
        const { title, description, slug, page_title, meta_description } = data;

        const formData = {
          course_id: courseId,
          title,
          description,
          slug,
          page_title,
          meta_description,
          allow_about: allowAbout === 'yes',
          allow_next_step: allowNextStep === 'yes',
          allow_special_note: allowSpecialNote === 'yes',
          watch_full_video: watchFull === 'yes',
          answer_quiz: answerQuiz === 'yes',
          coach_button: coachButton === 'yes',
          coach_unlock: coachUnlock === 'yes',
          allow_discussion: allowDiscussion === 'yes',
          allow_downloads: allowDownloads === 'yes',
          allow_resources: allowResources === 'yes',
          about,
          next_step: nextStep,
          special_note: specialNote,
          special_notes_coach: coacheSpace,
          thumbnail_id: '',
          video_id: '',
        };

        if (responseThumbnails) {
          formData.thumbnail_id = responseThumbnails.data.id;
        }

        if (responseVideo) {
          formData.video_id = responseVideo.data.id;
        }

        const response = await api.post('lessons', formData);

        if (response.data) {
          const downloadsPromise = new Promise<void>((resolve) => {
            if (downloads.length > 0) {
              setStatus('Adjusting download files...');
              const lastIndex = downloads.length - 1;
              downloads.forEach(async (download, index) => {
                const thumbData = new FormData();
                thumbData.append('thumbnail', download.thumb as File);
                const responseThumb = await api.post('thumbnails', thumbData);

                if (responseThumb.data) {
                  const downloadData = new FormData();
                  downloadData.append('lesson_id', response.data.id);
                  downloadData.append('thumbnail_id', responseThumb.data.id);
                  downloadData.append('download', download.download as File);
                  await api.post('downloads', downloadData);
                }

                if (lastIndex === index) {
                  resolve();
                }
              });
            } else {
              resolve();
            }
          });

          await downloadsPromise;

          const resourcesPromise = new Promise<void>((resolve) => {
            if (resources.length > 0) {
              setStatus('Adjusting download files...');
              const lastIndex = resources.length - 1;
              resources.forEach(async (resource, index) => {
                const thumbData = new FormData();
                thumbData.append('thumbnail', resource.thumb as File);
                const responseThumb = await api.post('thumbnails', thumbData);

                if (responseThumb.data) {
                  const resourceData = {
                    thumbnail_id: responseThumb.data.id,
                    lesson_id: response.data.id,
                    link: resource.resource,
                  };

                  await api.post('resources', resourceData);
                }

                if (lastIndex === index) {
                  resolve();
                }
              });
            } else {
              resolve();
            }
          });

          await resourcesPromise;

          if (questions.length > 0) {
            const examFormData = {
              lesson_id: response.data.id,
            };

            const responseExam = await api.post('exams', examFormData);
            const exam_id = responseExam.data.id;

            const quizzesPromise = new Promise<void>((resolve) => {
              const lastIndex = questions.length - 1;
              questions.forEach(async (question, index) => {
                if (question.status && question.question.length > 0) {
                  const quiz_id = question.id;
                  switch (question.status) {
                    case 'UPDATED':
                      question.options.forEach(async (option) => {
                        const option_id = option.id;
                        const optionFormData = {
                          quiz_id,
                          answer: option.answer,
                          correct_answer: option.correct_answer,
                        };
                        if (option.status && option.answer.length > 0) {
                          switch (option.status) {
                            case 'UPDATED':
                              await api.put(
                                `options/${option_id}`,
                                optionFormData
                              );
                              break;
                            case 'DELETED':
                              if (option_id) {
                                await api.delete(`options/${option_id}`);
                              }
                              break;
                            default:
                              await api.post('options', optionFormData);
                              break;
                          }
                        }
                      });
                      break;
                    case 'DELETED':
                      if (quiz_id) {
                        await api.delete(`quizzes/${quiz_id}`);
                      }
                      break;
                    default:
                      const questionFormData = {
                        exam_id,
                        question: question.question,
                      };
                      const responseQuestion = await api.post(
                        'quizzes',
                        questionFormData
                      );
                      if (responseQuestion.data) {
                        question.options.forEach(async (option) => {
                          const optionFormData = {
                            quiz_id: responseQuestion.data.id,
                            answer: option.answer,
                            correct_answer: option.correct_answer,
                          };
                          await api.post('options', optionFormData);
                        });
                      }
                      break;
                  }
                }

                if (lastIndex === index) {
                  resolve();
                }
              });
            });

            await quizzesPromise;
          }

          Swal.fire(
            'Good job!',
            'Lesson successfully registered.',
            'success'
          ).then(() => {
            setLoading(false);
            history.push(
              `${process.env.PUBLIC_URL}/courses/${params.slug}/lessons`
            );
          });
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          setAnswersHasError(!!errors.quizzes);
          setAboutError(errors.about);
          setNextStepError(errors.next_step);
          setSpecialNoteError(errors.special_note);
          setDownloadsError(errors.downloads);
          setResourcesError(errors.resources);
          formRef.current?.setErrors(errors);
          setLoading(false);
        } else {
          Swal.fire(
            'Opss...',
            'An error has occurred, please try again.',
            'error'
          ).then(() => setLoading(false));
        }
      }
    },
    [
      about,
      allowAbout,
      allowDiscussion,
      allowDownloads,
      allowNextStep,
      allowResources,
      allowSpecialNote,
      answerQuiz,
      coachButton,
      coachUnlock,
      coacheSpace,
      courseId,
      downloads,
      history,
      nextStep,
      params.slug,
      questions,
      resources,
      specialNote,
      thumbnailSelected,
      videoDuration,
      videoSelected,
      watchFull,
    ]
  );

  return (
    <Container>
      <div className="container-fluid container-xxl bg-gray">
        <Form ref={formRef} onSubmit={handleSubmit} className="row">
          <div className="col-12 p-0 pt-5 text-center">
            <CoursesActionTitle
              lightTitle="Create"
              boldTitle="Lesson"
              description="Surprisingly, there is a very vocal faction of the design
              community that wants to see filler text banished the original
              sources his will just slow down the design process."
            />
          </div>
          <div className="col-12">
            <div className="row justify-content-center my-5">
              <div className="col-11 col-lg-10 col-xl-8 col-xxl-7 overflow-auto pb-3 pb-md-0">
                <div className="d-flex ml-auto align-items-center min-width">
                  <Link
                    to={`${process.env.PUBLIC_URL}/courses/update/${params.slug}`}
                    className="w-100 text-center btn-grey step-lesson rounded-pill"
                  >
                    <span className="d-block px-3 py-3">Update Course</span>
                  </Link>
                  <hr className="w-25 w-md-50 border-gray mx-2" />
                  <Link
                    to={`${process.env.PUBLIC_URL}/courses/${params.slug}/lessons`}
                    className="w-100 text-center btn-grey step-lesson rounded-pill"
                  >
                    <span className="d-block px-3 py-3">Lessons</span>
                  </Link>
                  <hr className="w-25 w-md-50 border-gray mx-2" />
                  <div className="w-100 text-center rounded-pill btn-light-grey">
                    <span className="d-block px-3 py-3">Create Lesson</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-xxl-3 px-1 px-md-3">
            <VideoInfo
              id="videoInfo"
              className="d-md-flex flex-wrap flex-xl-column"
            >
              <div className="col-md-6 col-xl-12 px-0 pr-md-4 pr-xl-0 bd-right">
                <label htmlFor="thumbnail" className="small">
                  Thumbnail
                </label>
                <label
                  htmlFor="thumbnail"
                  className="btn bg-gray text-center w-100 p-0"
                >
                  {thumbnail ? (
                    <Img src={thumbnail} className="w-100" />
                  ) : (
                    <div className="d-flex justify-content-center align-items-center py-5">
                      <img
                        src={imgThumb}
                        alt="AddThumbnail"
                        className="w-25 mb-2"
                      />
                    </div>
                  )}
                </label>
                <Input
                  type="file"
                  id="thumbnail"
                  name="thumbnail"
                  className="d-none"
                  onChange={handleSelectThumbnail}
                />
              </div>
              <div className="col-md-6 col-xl-12 mt-5 mt-md-0 px-0 pl-md-4 pl-xl-0">
                <label htmlFor="video" className="small">
                  Video
                </label>
                {video ? (
                  <VideoPreview className="p-relative">
                    <Player src={video} />
                    <button
                      type="button"
                      onClick={handleRemoveVideo}
                      className="mt-2 p-absolute remove-video"
                    >
                      <span className="d-block py-1 px-2">X</span>
                    </button>
                  </VideoPreview>
                ) : (
                  <label
                    htmlFor="video"
                    className="btn bg-gray text-center w-100 p-0 mt-xl-2"
                  >
                    <div className="d-flex justify-content-center align-items-center py-5">
                      <img
                        src={videoThumb}
                        alt="AddBioPhoto"
                        className="w-25 mb-2"
                      />
                    </div>
                  </label>
                )}
                <Input
                  type="file"
                  id="video"
                  name="video"
                  className="d-none"
                  onChange={handleSelectVideo}
                />
              </div>
              <div className="row px-0 mt-5 mt-xl-0">
                <div className="col-sm-6 col-lg-4 col-xl-12 mt-3 px-md-4 my-xl-3">
                  <label htmlFor="duration" className="small">
                    Duration
                  </label>
                  <InputMask
                    kind="datetime"
                    options={{
                      format: 'HH:mm:ss',
                    }}
                    id="duration"
                    name="duration"
                    className="py-3"
                    onChange={handleChangeDuration}
                    onBlur={handleBlurDuration}
                    value={videoDuration}
                    placeholder="00:00:00"
                  />
                </div>

                <div className="col-sm-6 col-lg-4 col-xl-12 mt-3 px-md-4">
                  <label className="small">
                    Watch full video before continue
                  </label>
                  <div className="d-flex text-center">
                    <label
                      className={`btn w-100 mx-1 btn-radio ${
                        watchFull === 'yes' ? 'btn-grey' : 'btn-dark'
                      }`}
                      htmlFor="watch_full_video_yes"
                    >
                      <span className="d-block py-2">YES</span>
                      <Input
                        type="radio"
                        onChange={changeWatchFull}
                        name="watch_full"
                        id="watch_full_video_yes"
                        className="d-none"
                        value="yes"
                        checked={watchFull === 'yes'}
                      />
                    </label>
                    <label
                      className={`btn w-100 mx-1 btn-radio ${
                        watchFull === 'no' ? 'btn-grey' : 'btn-dark'
                      }`}
                      htmlFor="watch_full_video_no"
                    >
                      <span className="d-block py-2">NO</span>
                      <Input
                        type="radio"
                        onChange={changeWatchFull}
                        name="watch_full"
                        id="watch_full_video_no"
                        className="d-none"
                        value="no"
                        checked={watchFull === 'no'}
                      />
                    </label>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-xl-12 mt-3 px-md-4">
                  <label className="small">Answer QUIZ before continue</label>
                  <div className="d-flex text-center">
                    <label
                      className={`btn w-100 mx-1 btn-radio ${
                        answerQuiz === 'yes' ? 'btn-grey' : 'btn-dark'
                      }`}
                      htmlFor="answer_quiz_yes"
                    >
                      <span className="d-block py-2">YES</span>
                      <Input
                        type="radio"
                        onChange={changeAnswerQuiz}
                        name="answer_quiz"
                        id="answer_quiz_yes"
                        className="d-none"
                        value="yes"
                        checked={answerQuiz === 'yes'}
                      />
                    </label>
                    <label
                      className={`btn w-100 mx-1 btn-radio ${
                        answerQuiz === 'no' ? 'btn-grey' : 'btn-dark'
                      }`}
                      htmlFor="answer_quiz_no"
                    >
                      <span className="d-block py-2">NO</span>
                      <Input
                        type="radio"
                        onChange={changeAnswerQuiz}
                        name="answer_quiz"
                        id="answer_quiz_no"
                        className="d-none"
                        value="no"
                        checked={answerQuiz === 'no'}
                      />
                    </label>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-xl-12 mt-3 px-md-4">
                  <label className="small">Contact coach button</label>
                  <div className="d-flex text-center">
                    <label
                      className={`btn w-100 mx-1 btn-radio ${
                        coachButton === 'yes' ? 'btn-grey' : 'btn-dark'
                      }`}
                      htmlFor="coach_button_yes"
                    >
                      <span className="d-block py-2">YES</span>
                      <Input
                        type="radio"
                        onChange={changeCoachButton}
                        name="coach_button"
                        id="coach_button_yes"
                        className="d-none"
                        value="yes"
                        checked={coachButton === 'yes'}
                      />
                    </label>
                    <label
                      className={`btn w-100 mx-1 btn-radio ${
                        coachButton === 'no' ? 'btn-grey' : 'btn-dark'
                      }`}
                      htmlFor="coach_button_no"
                    >
                      <span className="d-block py-2">NO</span>
                      <Input
                        type="radio"
                        onChange={changeCoachButton}
                        name="coach_button"
                        id="coach_button_no"
                        className="d-none"
                        value="no"
                        checked={coachButton === 'no'}
                      />
                    </label>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-xl-12 mt-3 px-md-4">
                  <label className="small">Coach must unlock next step</label>
                  <div className="d-flex text-center">
                    <label
                      className={`btn w-100 mx-1 btn-radio ${
                        coachUnlock === 'yes' ? 'btn-grey' : 'btn-dark'
                      }`}
                      htmlFor="coach_unlock_yes"
                    >
                      <span className="d-block py-2">YES</span>
                      <Input
                        type="radio"
                        onChange={changeCoachUnlock}
                        name="coach_unlock"
                        id="coach_unlock_yes"
                        className="d-none"
                        value="yes"
                        checked={coachUnlock === 'yes'}
                      />
                    </label>
                    <label
                      className={`btn w-100 mx-1 btn-radio ${
                        coachUnlock === 'no' ? 'btn-grey' : 'btn-dark'
                      }`}
                      htmlFor="coach_unlock_no"
                    >
                      <span className="d-block py-2">NO</span>
                      <Input
                        type="radio"
                        onChange={changeCoachUnlock}
                        name="coach_unlock"
                        id="coach_unlock_no"
                        className="d-none"
                        value="no"
                        checked={coachUnlock === 'no'}
                      />
                    </label>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-xl-12 mt-3 px-md-4">
                  <label className="small">Allow about</label>
                  <div className="d-flex text-center">
                    <label
                      className={`btn w-100 mx-1 btn-radio ${
                        allowAbout === 'yes' ? 'btn-grey' : 'btn-dark'
                      }`}
                      htmlFor="allow_about_yes"
                    >
                      <span className="d-block py-2">YES</span>
                      <Input
                        type="radio"
                        onChange={changeAllowAbout}
                        name="allow_about"
                        id="allow_about_yes"
                        className="d-none"
                        value="yes"
                        checked={allowAbout === 'yes'}
                      />
                    </label>
                    <label
                      className={`btn w-100 mx-1 btn-radio ${
                        allowAbout === 'no' ? 'btn-grey' : 'btn-dark'
                      }`}
                      htmlFor="allow_about_no"
                    >
                      <span className="d-block py-2">NO</span>
                      <Input
                        type="radio"
                        onChange={changeAllowAbout}
                        name="allow_about"
                        id="allow_about_no"
                        className="d-none"
                        value="no"
                        checked={allowAbout === 'no'}
                      />
                    </label>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-xl-12 mt-3 px-md-4">
                  <label className="small">Allow next step</label>
                  <div className="d-flex text-center">
                    <label
                      className={`btn w-100 mx-1 btn-radio ${
                        allowNextStep === 'yes' ? 'btn-grey' : 'btn-dark'
                      }`}
                      htmlFor="allow_next_step_yes"
                    >
                      <span className="d-block py-2">YES</span>
                      <Input
                        type="radio"
                        onChange={changeAllowNextStep}
                        name="allow_next_step"
                        id="allow_next_step_yes"
                        className="d-none"
                        value="yes"
                        checked={allowNextStep === 'yes'}
                      />
                    </label>
                    <label
                      className={`btn w-100 mx-1 btn-radio ${
                        allowNextStep === 'no' ? 'btn-grey' : 'btn-dark'
                      }`}
                      htmlFor="allow_next_step_no"
                    >
                      <span className="d-block py-2">NO</span>
                      <Input
                        type="radio"
                        onChange={changeAllowNextStep}
                        name="allow_next_step"
                        id="allow_next_step_no"
                        className="d-none"
                        value="no"
                        checked={allowNextStep === 'no'}
                      />
                    </label>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-xl-12 mt-3 px-md-4">
                  <label className="small">Allow special note</label>
                  <div className="d-flex text-center">
                    <label
                      className={`btn w-100 mx-1 btn-radio ${
                        allowSpecialNote === 'yes' ? 'btn-grey' : 'btn-dark'
                      }`}
                      htmlFor="allow_special_note_yes"
                    >
                      <span className="d-block py-2">YES</span>
                      <Input
                        type="radio"
                        onChange={changeAllowSpecialNote}
                        name="allow_special_note"
                        id="allow_special_note_yes"
                        className="d-none"
                        value="yes"
                        checked={allowSpecialNote === 'yes'}
                      />
                    </label>
                    <label
                      className={`btn w-100 mx-1 btn-radio ${
                        allowSpecialNote === 'no' ? 'btn-grey' : 'btn-dark'
                      }`}
                      htmlFor="allow_special_note_no"
                    >
                      <span className="d-block py-2">NO</span>
                      <Input
                        type="radio"
                        onChange={changeAllowSpecialNote}
                        name="allow_special_note"
                        id="allow_special_note_no"
                        className="d-none"
                        value="no"
                        checked={allowSpecialNote === 'no'}
                      />
                    </label>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-xl-12 mt-3 px-md-4">
                  <label className="small">Allow discussion</label>
                  <div className="d-flex text-center">
                    <label
                      className={`btn w-100 mx-1 btn-radio ${
                        allowDiscussion === 'yes' ? 'btn-grey' : 'btn-dark'
                      }`}
                      htmlFor="allow_discussion_yes"
                    >
                      <span className="d-block py-2">YES</span>
                      <Input
                        type="radio"
                        onChange={changeAllowDiscussion}
                        name="allow_discussion"
                        id="allow_discussion_yes"
                        className="d-none"
                        value="yes"
                        checked={allowDiscussion === 'yes'}
                      />
                    </label>
                    <label
                      className={`btn w-100 mx-1 btn-radio ${
                        allowDiscussion === 'no' ? 'btn-grey' : 'btn-dark'
                      }`}
                      htmlFor="allow_discussion_no"
                    >
                      <span className="d-block py-2">NO</span>
                      <Input
                        type="radio"
                        onChange={changeAllowDiscussion}
                        name="allow_discussion"
                        id="allow_discussion_no"
                        className="d-none"
                        value="no"
                        checked={allowDiscussion === 'no'}
                      />
                    </label>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-xl-12 mt-3 px-md-4">
                  <label className="small">Allow downloads</label>
                  <div className="d-flex text-center">
                    <label
                      className={`btn w-100 mx-1 btn-radio ${
                        allowDownloads === 'yes' ? 'btn-grey' : 'btn-dark'
                      }`}
                      htmlFor="allow_downloads_yes"
                    >
                      <span className="d-block py-2">YES</span>
                      <Input
                        type="radio"
                        onChange={changeAllowDownloads}
                        name="allow_downloads"
                        id="allow_downloads_yes"
                        className="d-none"
                        value="yes"
                        checked={allowDownloads === 'yes'}
                      />
                    </label>
                    <label
                      className={`btn w-100 mx-1 btn-radio ${
                        allowDownloads === 'no' ? 'btn-grey' : 'btn-dark'
                      }`}
                      htmlFor="allow_downloads_no"
                    >
                      <span className="d-block py-2">NO</span>
                      <Input
                        type="radio"
                        onChange={changeAllowDownloads}
                        name="allow_downloads"
                        id="allow_downloads_no"
                        className="d-none"
                        value="no"
                        checked={allowDownloads === 'no'}
                      />
                    </label>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-xl-12 mt-3 px-md-4">
                  <label className="small">Allow resources</label>
                  <div className="d-flex text-center">
                    <label
                      className={`btn w-100 mx-1 btn-radio ${
                        allowResources === 'yes' ? 'btn-grey' : 'btn-dark'
                      }`}
                      htmlFor="allow_resources_yes"
                    >
                      <span className="d-block py-2">YES</span>
                      <Input
                        type="radio"
                        onChange={changeAllowResource}
                        name="allow_resources"
                        id="allow_resources_yes"
                        className="d-none"
                        value="yes"
                        checked={allowResources === 'yes'}
                      />
                    </label>
                    <label
                      className={`btn w-100 mx-1 btn-radio ${
                        allowResources === 'no' ? 'btn-grey' : 'btn-dark'
                      }`}
                      htmlFor="allow_resources_no"
                    >
                      <span className="d-block py-2">NO</span>
                      <Input
                        type="radio"
                        onChange={changeAllowResource}
                        name="allow_resources"
                        id="allow_resources_no"
                        className="d-none"
                        value="no"
                        checked={allowResources === 'no'}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </VideoInfo>
          </div>
          <div className="col-xl-8 col-xxl-9 px-1 px-md-3">
            <LessonInfo>
              <h3 className="h5">New Lesson</h3>
              <div className="row">
                <div
                  className={`order-0 order-lg-0 ${
                    answerQuiz === 'yes' ? 'col-lg-8' : 'col-12'
                  }`}
                >
                  <div className="mt-3">
                    <label htmlFor="title" className="small">
                      Title
                    </label>
                    <Input
                      id="title"
                      name="title"
                      className="py-3"
                      onChange={handleChangeTitle}
                    />
                  </div>
                </div>
                {answerQuiz === 'yes' && (
                  <div className="col-lg-4">
                    <Quizzes
                      onChange={handleChangeQuizzes}
                      hasValidateError={answersHasError}
                      required
                    />
                  </div>
                )}
                <div className="col-12 order-1">
                  <div className="mt-3">
                    <label htmlFor="description" className="small">
                      Short Description
                    </label>
                    <Textarea
                      id="description"
                      className="pb-5"
                      name="description"
                    />
                  </div>
                </div>
              </div>
            </LessonInfo>
            <LessonInfo className="mt-4">
              <h3 className="h5">SEO</h3>
              <div className="row">
                <div className="col-lg-6">
                  <div className="mt-3">
                    <label htmlFor="slug" className="small">
                      URL
                    </label>
                    <div className="d-flex align-items-center link-preview p-relative">
                      <span className="mb-0 before-slug py-3">/lesson/</span>
                      <Input
                        id="slug"
                        name="slug"
                        className="py-3"
                        value={url}
                        onChange={handleChangeSlug}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mt-3">
                    <label htmlFor="page_title" className="small">
                      Page Title
                    </label>
                    <Input id="page_title" name="page_title" className="py-3" />
                  </div>
                </div>
                <div className="col-12">
                  <div className="mt-3">
                    <label htmlFor="meta_description" className="small">
                      Meta Description
                    </label>
                    <Input
                      id="meta_description"
                      name="meta_description"
                      className="py-3"
                    />
                  </div>
                </div>
              </div>
            </LessonInfo>
            <LessonInfo className="mt-4">
              <div className="row">
                <Actions className="col-12 p-3">
                  {(allowAbout === 'yes' ||
                    allowAbout === 'yes' ||
                    allowNextStep === 'yes' ||
                    allowSpecialNote === 'yes' ||
                    allowDiscussion === 'yes' ||
                    allowDownloads === 'yes' ||
                    allowResources === 'yes') && (
                    <>
                      <Options className="mb-3">
                        <div className="d-flex justify-content-around p-2 button-group">
                          {allowAbout === 'yes' && (
                            <div>
                              <button
                                type="button"
                                className={`border-0 bg-transparent font-weight-bold small ${
                                  optionSelected === 'about' ? 'active' : ''
                                }`}
                                onClick={() => setOptionSelected('about')}
                              >
                                About
                              </button>
                              {aboutError && (
                                <small className="d-flex justify-content-around error">
                                  <span>{aboutError}</span>
                                </small>
                              )}
                            </div>
                          )}
                          {allowNextStep === 'yes' && (
                            <div>
                              <button
                                type="button"
                                className={`border-0 bg-transparent font-weight-bold small ${
                                  optionSelected === 'next-step' ? 'active' : ''
                                }`}
                                onClick={() => setOptionSelected('next-step')}
                              >
                                Next Step
                              </button>
                              {nextStepError && (
                                <small className="d-flex justify-content-around error">
                                  <span>{nextStepError}</span>
                                </small>
                              )}
                            </div>
                          )}
                          {allowSpecialNote === 'yes' && (
                            <div>
                              <button
                                type="button"
                                className={`border-0 bg-transparent font-weight-bold small ${
                                  optionSelected === 'special-note'
                                    ? 'active'
                                    : ''
                                }`}
                                onClick={() =>
                                  setOptionSelected('special-note')
                                }
                              >
                                Special Note
                              </button>
                              {specialNoteError && (
                                <small className="d-flex justify-content-around error">
                                  <span>{specialNoteError}</span>
                                </small>
                              )}
                            </div>
                          )}
                          {allowDiscussion === 'yes' && (
                            <div>
                              <button
                                type="button"
                                className={`border-0 bg-transparent font-weight-bold small ${
                                  optionSelected === 'discussion'
                                    ? 'active'
                                    : ''
                                }`}
                                onClick={() => setOptionSelected('discussion')}
                              >
                                Discussion
                              </button>
                            </div>
                          )}
                          {allowDownloads === 'yes' && (
                            <div>
                              <button
                                type="button"
                                className={`border-0 bg-transparent font-weight-bold small ${
                                  optionSelected === 'downloads' ? 'active' : ''
                                }`}
                                onClick={() => setOptionSelected('downloads')}
                              >
                                Downloads
                              </button>
                              {downloadsError && (
                                <small className="d-flex justify-content-around error">
                                  <span>{downloadsError}</span>
                                </small>
                              )}
                            </div>
                          )}
                          {allowResources === 'yes' && (
                            <div>
                              <button
                                type="button"
                                className={`border-0 bg-transparent font-weight-bold small ${
                                  optionSelected === 'resources' ? 'active' : ''
                                }`}
                                onClick={() => setOptionSelected('resources')}
                              >
                                Resources
                              </button>
                              {resourcesError && (
                                <small className="d-flex justify-content-around error">
                                  <span>{resourcesError}</span>
                                </small>
                              )}
                            </div>
                          )}
                        </div>
                      </Options>
                      <Content>
                        {allowAbout === 'yes' && (
                          <div
                            className={`about ${
                              optionSelected === 'about' ? 'd-block' : 'd-none'
                            }`}
                          >
                            <CKEditor
                              editor={ClassicEditor}
                              config={{
                                ckfinder: {
                                  uploadUrl:
                                    'http://localhost:3333/files/image-upload',
                                },
                              }}
                              onChange={handleChangeAbout}
                            />
                          </div>
                        )}
                        {allowNextStep === 'yes' && (
                          <div
                            className={`next-step ${
                              optionSelected === 'next-step'
                                ? 'd-block'
                                : 'd-none'
                            }`}
                          >
                            <div className="row">
                              <div
                                className={
                                  coacheSpace ? 'col-md-6 col-xxl-7 ' : 'col-12'
                                }
                              >
                                <CKEditor
                                  editor={ClassicEditor}
                                  config={{
                                    ckfinder: {
                                      uploadUrl:
                                        'http://localhost:3333/files/image-upload',
                                    },
                                  }}
                                  onChange={handleChangeNextStep}
                                />
                                <button
                                  type="button"
                                  className={`${
                                    coacheSpace ? 'd-none' : 'd-block'
                                  } ml-auto btn-grey rounded-pill py-2 px-3 mt-2`}
                                  onClick={() => setCoacheSpace(true)}
                                >
                                  <span className="d-block py-1 px-2">
                                    Add Coach Space
                                  </span>
                                </button>
                              </div>

                              <div
                                className={`col-md-6 col-xxl-5 mt-4 mt-md-0 ${
                                  coacheSpace ? 'd-block' : 'd-none'
                                }`}
                              >
                                <div className="coach py-4 p-relative">
                                  <button
                                    type="button"
                                    onClick={() => setCoacheSpace(false)}
                                    className="btn button-close h5 font-weight-bold p-absolute"
                                  >
                                    x
                                  </button>
                                  <div className="d-flex flex-column align-items-center p-2">
                                    <div>
                                      <div className="coach-avatar rounded-circle d-block mb-3 mx-auto text-center">
                                        <p className="h6 font-weight-bold d-flex justify-content-center align-items-center h-100">
                                          Avatar
                                          <br /> Photo
                                        </p>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <img src={user} alt="User" />
                                        <p className="my-2 small ml-1">
                                          <b>Name:</b> Coach Name
                                        </p>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <img src={whatsapp} alt="Whatsapp" />
                                        <p className="my-2 small ml-1">
                                          <b>Phone/Text:</b> Coach Phone Number
                                        </p>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <img src={mail} alt="Mail" />
                                        <p className="my-2 small ml-1">
                                          <b>Email:</b> Coach E-mail
                                        </p>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <img src={skype} alt="Skype" />
                                        <p className="my-2 small ml-1">
                                          <b>Skype:</b> Coach Skype
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {allowSpecialNote === 'yes' && (
                          <div
                            className={`special-note ${
                              optionSelected === 'special-note'
                                ? 'd-block'
                                : 'd-none'
                            }`}
                          >
                            <CKEditor
                              editor={ClassicEditor}
                              config={{
                                ckfinder: {
                                  uploadUrl:
                                    'http://localhost:3333/files/image-upload',
                                },
                              }}
                              onChange={handleChangeSpecialNote}
                            />
                          </div>
                        )}
                        {allowDiscussion === 'yes' && (
                          <div
                            className={`discussion ${
                              optionSelected === 'discussion'
                                ? 'd-block'
                                : 'd-none'
                            }`}
                          >
                            <h2 className="h6 text-center font-weight-bold">
                              Let us know your thoughts about this lesson below
                            </h2>
                            <div className="bg-gray p-3">
                              <textarea
                                placeholder="Type Something..."
                                className="form-control"
                              />
                              <button
                                type="button"
                                className="d-block mt-2 mx-auto btn-dark"
                              >
                                <span className="d-block px-5 py-1">
                                  <img
                                    src={comment}
                                    alt="Comment"
                                    className="mr-2"
                                  />
                                  Submit Comment
                                </span>
                              </button>
                            </div>
                          </div>
                        )}
                        {allowDownloads === 'yes' && (
                          <Downloads
                            onChange={handleChangeDownloads}
                            active={optionSelected === 'downloads'}
                          />
                        )}
                        {allowResources === 'yes' && (
                          <Resources
                            onChange={handleChangeResources}
                            active={optionSelected === 'resources'}
                          />
                        )}
                      </Content>
                    </>
                  )}
                </Actions>
              </div>
              <div className="row align-items-end justify-content-end">
                <div className="col-lg-4">
                  <button type="submit" className="btn-grey py-3 w-100">
                    <span className="d-block font-weight-bold">Save</span>
                  </button>
                </div>
              </div>
            </LessonInfo>
          </div>
        </Form>
      </div>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <p className="font-weight-bold text-white mt-3">{status}</p>
        </div>
      )}
    </Container>
  );
};

export default CoursesRegister;
