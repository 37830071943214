import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useAuth } from '~/hooks/Auth';

import { Container, Menu } from './styles';

import logo from '~/assets/logotipos/logo.svg';

const Header: React.FC = () => {
  const location = useLocation();
  const { user, signOut } = useAuth();
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(false);
  }, [location]);

  return (
    <Container className="d-flex flex-lg-column justify-content-between justify-content-lg-around align-items-center text-center menu-header">
      <div className="d-flex flex-column py-lg-3">
        <Link to="/">
          <img src={logo} alt="logo" className="pl-2 pl-lg-0 w-75" />
        </Link>
      </div>
      <Menu active={active} className="w-auto w-lg-100">
        <button
          type="button"
          className="bg-transparent d-lg-none border-0 mx-3"
          onClick={() => setActive(!active)}
        >
          <span />
          <span />
          <span />
        </button>
        <div className="overflow d-flex flex-column p-absolute p-lg-relative menu-group active">
          <div className="d-flex flex-column pl-4 px-lg-0 py-5 py-lg-1 py-xl-3 h-lg-100 menu">
            {user.type === 'master' && (
              <>
                <Link
                  to={`${process.env.PUBLIC_URL}/dashboard`}
                  className={`menu-item px-2 px-lg-0 py-2 py-lg-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                    location.pathname.match(/\/dashboard.*/) ? 'active' : ''
                  }`}
                >
                  <div className="dashboard d-none d-lg-block" />
                  <small>Dashboard</small>
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/students`}
                  className={`menu-item px-2 px-lg-0 py-2 py-lg-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                    location.pathname.match(/\/students.*/) ? 'active' : ''
                  }`}
                >
                  <div className="students d-none d-lg-block" />
                  <small>Students</small>
                </Link>

                <Link
                  to={`${process.env.PUBLIC_URL}/coaches`}
                  className={`menu-item px-2 px-lg-0 py-2 py-lg-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                    location.pathname.match(/\/coaches.*/) ? 'active' : ''
                  }`}
                >
                  <div className="coaches d-none d-lg-block" />
                  <small>Coaches</small>
                </Link>

                <Link
                  to={`${process.env.PUBLIC_URL}/affiliates`}
                  className={`menu-item px-2 px-lg-0 py-2 py-lg-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                    location.pathname.match(/\/affiliates.*/) ? 'active' : ''
                  }`}
                >
                  <div className="affiliates d-none d-lg-block" />
                  <small>Affiliates</small>
                </Link>
              </>
            )}
            <Link
              to={`${process.env.PUBLIC_URL}/courses`}
              className={`menu-item px-2 px-lg-0 py-2 py-lg-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                location.pathname.match(/\/courses.*/) ? 'active' : ''
              }`}
            >
              <div className="courses d-none d-lg-block" />
              <small>Courses</small>
            </Link>
            {user.type === 'master' && (
              <>
                <Link
                  to={`${process.env.PUBLIC_URL}/resources/downloads`}
                  className={`menu-item px-2 px-lg-0 py-2 py-lg-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                    location.pathname.match(/\/resources.*/) ? 'active' : ''
                  }`}
                >
                  <div className="resources d-none d-lg-block" />
                  <small>Resources</small>
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/products`}
                  className={`menu-item px-2 px-lg-0 py-2 py-lg-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                    location.pathname.match(/\/products.*/) ? 'active' : ''
                  }`}
                >
                  <div className="product d-none d-lg-block" />
                  <small>Franchises</small>
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/faq`}
                  className={`menu-item px-2 px-lg-0 py-2 py-lg-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                    location.pathname.match(/\/faq.*/) ? 'active' : ''
                  }`}
                >
                  <div className="faq d-none d-lg-block" />
                  <small>FAQ</small>
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/categories/courses`}
                  className={`menu-item px-2 px-lg-0 py-2 py-lg-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                    location.pathname.match(/\/categories.*/) ? 'active' : ''
                  }`}
                >
                  <div className="categories d-none d-lg-block" />
                  <small>Categories</small>
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/order-review`}
                  className={`menu-item px-2 px-lg-0 py-2 py-lg-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                    location.pathname.match(/\/order.*/) ? 'active' : ''
                  }`}
                >
                  <div className="order d-none d-lg-block" />
                  <small>Order review</small>
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/scheduled-withdrawals`}
                  className={`menu-item px-2 px-lg-0 py-2 py-lg-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                    location.pathname.match(/\/scheduled-withdrawals.*/)
                      ? 'active'
                      : ''
                  }`}
                >
                  <div className="scheduled-withdrawals d-none d-lg-block" />
                  <small>Scheduled Withdrawals</small>
                </Link>
              </>
            )}
          </div>
          <div className="d-flex flex-column mb-5 mb-lg-0 pl-4 px-lg-0 py-3 logoff">
            <button
              type="button"
              onClick={() => signOut()}
              className="border-0 menu-item px-2 px-lg-0 py-2 py-lg-2 py-xxl-3 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center"
            >
              <div className="logout ml-0 mx-lg-auto" />
              <small>Logout</small>
            </button>
          </div>
        </div>
      </Menu>
    </Container>
  );
};

export default Header;
