import React, {
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
  useRef,
} from 'react';
import { Form } from '@unform/web';
import { Link, useHistory, useParams } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import Swal from 'sweetalert2';
import api from '~/services/api';

import {
  Container,
  VideoInfo,
  CourseInfo,
  Img,
  VideoPreview,
  Modal,
} from './styles';
import Input from '~/components/Input';
import InputMask from '~/components/InputMask';
import Textarea from '~/components/Textarea';
import Player from '~/components/Player';

import videoThumb from '~/assets/icons/video_thumb.svg';
import imgThumb from '~/assets/icons/img_thumb.svg';
import deleteIcon from '~/assets/icons/delete.svg';
import getValidationErros from '~/utils/getValidationsErrors';
import InputCategory, { IValue } from '~/components/InputCategory';
import CoursesActionTitle from '~/components/CoursesActionTitle';

interface IParams {
  slug: string;
}

interface ICategory {
  id: string;
  name: string;
}

interface ICourseCategory {
  category: ICategory;
  deleted_at: string;
}

interface courseFormData {
  thumbnail_title: string;
  title: string;
  subtitle: string;
  presented_by: string;
  description: string;
  slug: string;
  page_title: string;
  meta_description: string;
  price: string;
  show_promotion_price: string;
  promotion_price?: string;
}

const CoursesUpdate: React.FC = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [course, setCourse] = useState<courseFormData>({} as courseFormData);
  const [thumbnail, setThumbnail] = useState('');
  const [thumbnailSelected, setThumbnailSelected] = useState<File | null>(null);
  const [video, setVideo] = useState('');
  const [videoSelected, setVideoSelected] = useState<File | null>(null);
  const [url, setUrl] = useState('');
  const [courseId, setCourseId] = useState('');
  const [thumbnailId, setThumbnailId] = useState('');
  const [videoId, setVideoId] = useState('');
  const [videoDuration, setVideoDuration] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPromotionPrice, setShowPromotionPrice] = useState('no');
  const [categoriesData, setCategoriesData] = useState<IValue[]>([]);
  const [categoriesDeleted, setCategoriesDeleted] = useState<IValue[]>([]);
  const [status, setStatus] = useState('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    api
      .get(`courses/${params.slug}`, {
        params: {
          withCourseCategory: true,
          withThumbnail: true,
          withVideo: true,
          withCategory: true,
        },
      })
      .then((response) => {
        const data: courseFormData = {
          title: response.data.title,
          subtitle: response.data.subtitle,
          presented_by: response.data.presented_by,
          description: response.data.description,
          slug: response.data.slug,
          page_title: response.data.page_title,
          meta_description: response.data.meta_description,
          thumbnail_title: response.data.thumbnail
            ? response.data.thumbnail.title
            : '',
          price: response.data.price,
          show_promotion_price: response.data.show_promotion_price,
          promotion_price: response.data.promotion_price,
        };
        if (response.data.courseCategory.length > 0) {
          const coursesCategories = response.data.courseCategory.filter(
            (courseCategory: ICourseCategory) => !courseCategory.deleted_at
          );
          const dataCategories: IValue[] = coursesCategories.map(
            (courseCategory: ICourseCategory) => ({
              id: courseCategory.category.id,
              value: courseCategory.category.name,
            })
          );
          setCategoriesData(dataCategories);
        }
        setThumbnail(
          response.data.thumbnail ? response.data.thumbnail.thumbnail_url : ''
        );
        setVideo(response.data.video ? response.data.video.video_url : '');
        setUrl(response.data.slug);
        setCourseId(response.data.id);
        setThumbnailId(
          response.data.thumbnail ? response.data.thumbnail.id : ''
        );
        setVideoId(response.data.video ? response.data.video.id : '');
        setVideoDuration(
          response.data.video ? response.data.video.duration : ''
        );
        setShowPromotionPrice(
          response.data.show_promotion_price ? 'yes' : 'no'
        );
        setCourse(data);
        setLoading(false);
      });
  }, [params.slug]);

  const handleBlurDuration = useCallback((e) => {
    const duration = e.target.value;
    const [hours, minutes, seconds] = duration.split(':');

    let newHours = hours;
    let newMinutes = minutes;
    let newSeconds = seconds;

    if (newHours) {
      if (newHours.length < 2) {
        newHours += '0';
      }
    } else {
      newHours = '00';
    }

    if (newMinutes) {
      if (newMinutes > 59) {
        newMinutes = 59;
      } else if (newMinutes.length < 2) {
        newMinutes += '0';
      }
    } else {
      newMinutes = '00';
    }

    if (newSeconds) {
      if (newSeconds > 59) {
        newSeconds = 59;
      } else if (newSeconds.length < 2) {
        newSeconds += '0';
      }
    } else {
      newSeconds = '00';
    }

    setVideoDuration(`${newHours}:${newMinutes}:${newSeconds}`);
  }, []);

  useEffect(() => {
    if (video) {
      setTimeout(() => {
        const videoElement = document.querySelector(
          '#videoInfo video'
        ) as HTMLVideoElement;
        if (videoElement) {
          videoElement.onloadedmetadata = () => {
            const { duration } = videoElement;
            const hrs = `0${~~(duration / 3600)}`.slice(-2);
            const mins = `0${~~((duration % 3600) / 60)}`.slice(-2);
            const secs = `0${~~(duration % 60)}`.slice(-2);
            const durationData = `${hrs}:${mins}:${secs}`;
            setVideoDuration(durationData);
            const data = {
              target: {
                value: durationData,
              },
            };
            handleBlurDuration(data);
          };
        }
      }, 100);
    }
  }, [handleBlurDuration, video]);

  const handleSelectThumbnail = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const file = e.target.files[0];
        if (file) {
          setThumbnail(URL.createObjectURL(file));
          setThumbnailSelected(file);
        } else {
          setThumbnail('');
          setThumbnailSelected(null);
        }
      }
    },
    []
  );

  const handleSelectVideo = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file) {
        setVideo(URL.createObjectURL(file));
        setVideoSelected(file);
      } else {
        setVideo('');
        setVideoSelected(null);
        setVideoDuration('');
      }
    }
  }, []);

  const handleRemoveVideo = useCallback(() => {
    setVideo('');
    setVideoSelected(null);
    setVideoDuration('');
  }, []);

  const handleChangeDuration = useCallback((e) => {
    const duration = e.target.value;
    const [hours, minutes, seconds] = duration.split(':');
    let newMinutes = minutes;
    let newSeconds = seconds;

    if (newMinutes > 59) {
      newMinutes = 59;
    }

    if (newSeconds > 59) {
      newSeconds = 59;
    }

    setVideoDuration(`${hours}:${newMinutes}:${newSeconds}`);
  }, []);

  const changeShowPromotionPrice = useCallback((e) => {
    setShowPromotionPrice(e.target.value);
  }, []);

  const handleChangeTitle = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setUrl(
      e.target.value
        .replace(/[àáâãäå]/g, 'a')
        .replace(/æ/g, 'ae')
        .replace(/ç/g, 'c')
        .replace(/[èéêë]/g, 'e')
        .replace(/[ìíîï]/g, 'i')
        .replace(/ñ/g, 'n')
        .replace(/[òóôõö]/g, 'o')
        .replace(/œ/g, 'oe')
        .replace(/[ùúûü]/g, 'u')
        .replace(/[ýÿ]/g, 'y')
        .replace(/[^a-zA-Z0-9 -]/g, '')
        .replace(/ /g, '-')
        .toLowerCase()
    );
  }, []);

  const handleChangeSlug = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setUrl(
      e.target.value
        .replace(/[àáâãäå]/g, 'a')
        .replace(/æ/g, 'ae')
        .replace(/ç/g, 'c')
        .replace(/[èéêë]/g, 'e')
        .replace(/[ìíîï]/g, 'i')
        .replace(/ñ/g, 'n')
        .replace(/[òóôõö]/g, 'o')
        .replace(/œ/g, 'oe')
        .replace(/[ùúûü]/g, 'u')
        .replace(/[ýÿ]/g, 'y')
        .replace(/[^a-zA-Z0-9 -]/g, '')
        .replace(/ /g, '-')
        .toLowerCase()
    );
  }, []);

  const handleSelectCategories = useCallback((data) => {
    setCategoriesData(data);
  }, []);

  const handleRemoveCategory = useCallback((data) => {
    if (data.id) {
      setCategoriesDeleted((state) => [...state, data]);
    }
  }, []);

  const handleShow = useCallback(() => {
    setShow(true);
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleDelete = useCallback(async () => {
    setLoading(true);
    try {
      await api.delete(`courses/${courseId}`);
      Swal.fire('Good job!', 'Module deleted successfully.', 'success').then(
        () => {
          handleClose();
          setLoading(false);
          history.push(`${process.env.PUBLIC_URL}/courses`);
        }
      );
    } catch (error) {
      Swal.fire(
        'Opss...',
        'An error has occurred, please try again.',
        'error'
      ).then(() => setLoading(false));
    }
  }, [courseId, handleClose, history]);

  const handleSubmit = useCallback(
    async (data: courseFormData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          title: Yup.string().required('Title is a required'),
          subtitle: Yup.string().required('Subtitle is a required'),
          presented_by: Yup.string().required('Presented by is a required'),
          description: Yup.string().required('Description is a required'),
          slug: Yup.string().required('URL is a required'),
          page_title: Yup.string().required('Page Title is a required'),
          meta_description: Yup.string().required(
            'Meta Description is a required'
          ),
          thumbnail_title: Yup.string(),
          duration: Yup.string(),
          price: Yup.string().required('Price Short Title is a required'),
          show_promotion_price: Yup.string(),
          promotion_price: Yup.string().when('$exist', {
            is: (exist: boolean) => exist,
            then: Yup.string().required('Promotion Price is a required'),
            otherwise: Yup.string(),
          }),
          categories: Yup.string().when('$categoryFilled', {
            is: (categoryFilled: boolean) => categoryFilled,
            then: Yup.string(),
            otherwise: Yup.string().required('Categories is a required'),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            exist: showPromotionPrice === 'yes',
            categoryFilled: categoriesData.length > 0,
          },
        });

        if (categoriesDeleted.length > 0) {
          categoriesDeleted.forEach(async (category) => {
            const coursesCategoriesResponse = await api.get(
              `courses-categories/${courseId}/${category.id}`
            );

            if (coursesCategoriesResponse.data) {
              await api.delete(
                `courses-categories/${coursesCategoriesResponse.data.id}`
              );
            }
          });
        }

        let responseThumbnails;
        let responseVideo;

        if (thumbnailId || thumbnailSelected) {
          setStatus('Uploading thumbnail...');
          if (thumbnailSelected) {
            const thumbnailData = new FormData();
            thumbnailData.append('thumbnail', thumbnailSelected as File);
            thumbnailData.append('title', data.thumbnail_title);
            responseThumbnails = await api.post('thumbnails', thumbnailData);
          } else {
            responseThumbnails = {
              data: {
                id: thumbnailId,
              },
            };
          }
        }

        if (videoId || videoSelected) {
          setStatus('Uploading video...');
          if (videoSelected) {
            const videoData = new FormData();
            videoData.append('video', videoSelected as File);
            videoData.append('duration', videoDuration);
            responseVideo = await api.post('videos', videoData);
          } else {
            responseVideo = {
              data: {
                id: videoId,
              },
            };
          }
        }

        setStatus('Updating course...');
        const {
          title,
          subtitle,
          presented_by,
          description,
          slug,
          page_title,
          meta_description,
          price,
          promotion_price,
        } = data;

        const formData = {
          title,
          subtitle,
          presented_by,
          description,
          slug,
          page_title,
          meta_description,
          price: parseFloat(price.replace(/[$,]/g, '')),
          show_promotion_price: showPromotionPrice === 'yes',
          promotion_price: promotion_price
            ? parseFloat(promotion_price.replace(/[$,]/g, ''))
            : undefined,
          thumbnail_id: '',
          video_id: '',
        };

        if (responseThumbnails) {
          formData.thumbnail_id = responseThumbnails.data.id;
        }

        if (responseVideo) {
          formData.video_id = responseVideo.data.id;
        }

        const response = await api.put(`courses/${courseId}`, formData);

        if (response.data) {
          setStatus('Adjusting categories...');
          if (categoriesData.length > 0) {
            categoriesData.forEach(async (category) => {
              if (category.id) {
                api
                  .get(`courses-categories/${courseId}/${category.id}`)
                  .catch(async () => {
                    const coursesCategoriesFormData = {
                      course_id: courseId,
                      category_id: category.id,
                    };
                    await api.post(
                      'courses-categories',
                      coursesCategoriesFormData
                    );
                  });
              }
            });
          }

          Swal.fire(
            'Good job!',
            'Course updated successfully.',
            'success'
          ).then(() => {
            setLoading(false);
            history.push(
              `${process.env.PUBLIC_URL}/courses/${response.data.slug}/lessons`
            );
          });
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
          setLoading(false);
        } else {
          Swal.fire(
            'Opss...',
            'An error has occurred, please try again.',
            'error'
          ).then(() => setLoading(false));
        }
      }
    },
    [
      categoriesData,
      categoriesDeleted,
      courseId,
      history,
      showPromotionPrice,
      thumbnailId,
      thumbnailSelected,
      videoDuration,
      videoId,
      videoSelected,
    ]
  );

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <Form
          ref={formRef}
          initialData={course}
          onSubmit={handleSubmit}
          className="row"
        >
          <div className="col-12 mt-3">
            <button
              type="button"
              className="text-danger float-right delete mr-3"
              onClick={handleShow}
            >
              <img src={deleteIcon} alt="delete" className="mr-2" />
              Delete
            </button>
          </div>
          <div className="col-12 pt-3 text-center">
            <CoursesActionTitle
              lightTitle="Update"
              boldTitle="Course"
              description="Surprisingly, there is a very vocal faction of the design
              community that wants to see filler text banished the original
              sources his will just slow down the design process."
            />
          </div>
          <div className="col-12">
            <div className="row justify-content-center my-5">
              <div className="col-11 col-lg-9 col-xl-8 col-xxl-7 overflow-auto pb-3 pb-md-0">
                <div className="d-flex ml-auto align-items-center min-width">
                  <div className="w-100 text-center btn-light-grey step-course rounded-pill">
                    <span className="d-block px-3 py-3">Update Course</span>
                  </div>
                  <hr className="w-25 w-md-50 border-gray mx-2" />
                  <Link
                    to={`${process.env.PUBLIC_URL}/courses/${params.slug}/lessons`}
                    className="w-100 text-center btn-grey step-lesson rounded-pill"
                  >
                    <span className="d-block px-3 py-3">Lessons</span>
                  </Link>
                  <hr className="w-25 w-md-50 border-gray mx-2" />
                  <Link
                    to={`${process.env.PUBLIC_URL}/courses/${params.slug}/modules`}
                    className="w-100 text-center btn-grey step-lesson rounded-pill"
                  >
                    <span className="d-block px-3 py-3">Modules</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-xxl-3 px-0 px-lg-3 pr-xl-0">
            <VideoInfo
              id="videoInfo"
              className="d-flex flex-wrap flex-xl-column justify-content-between"
            >
              <div className="col-md-6 col-lg-4 col-xl-12 pr-md-4 pl-lg-0 px-xl-0 bd-right">
                <label htmlFor="thumbnail" className="small">
                  Thumbnail
                </label>
                <label
                  htmlFor="thumbnail"
                  className="btn bg-gray text-center w-100 p-0"
                >
                  {thumbnail ? (
                    <Img src={thumbnail} className="w-100" />
                  ) : (
                    <div className="d-flex justify-content-center align-items-center py-5">
                      <img
                        src={imgThumb}
                        alt="AddThumbnail"
                        className="w-25 mb-2"
                      />
                    </div>
                  )}
                </label>
                <Input
                  type="file"
                  id="thumbnail"
                  name="thumbnail"
                  className="d-none"
                  onChange={handleSelectThumbnail}
                />
                <div className="mt-3 mb-xl-4">
                  <label htmlFor="thumbnail_title" className="small">
                    Thumbnail Short Title
                  </label>
                  <Input
                    id="thumbnail_title"
                    name="thumbnail_title"
                    className="py-3"
                  />
                </div>
              </div>
              <hr className="d-none d-xl-block hr-gray my-4" />
              <div className="col-md-6 col-lg-4 col-xl-12 pl-md-4 pr-lg-0 px-xl-0 mt-5 mt-md-0">
                <label htmlFor="video" className="small">
                  Preview of the course
                </label>
                {video ? (
                  <VideoPreview className="p-relative">
                    <Player src={video} />
                    <button
                      type="button"
                      onClick={handleRemoveVideo}
                      className="mt-2 p-absolute remove-video"
                    >
                      <span className="d-block py-1 px-2">X</span>
                    </button>
                  </VideoPreview>
                ) : (
                  <label
                    htmlFor="video"
                    className="btn bg-gray text-center w-100 p-0"
                  >
                    <div className="d-flex justify-content-center align-items-center py-5">
                      <img
                        src={videoThumb}
                        alt="AddBioPhoto"
                        className="w-25 mb-2"
                      />
                    </div>
                  </label>
                )}
                <Input
                  type="file"
                  id="video"
                  name="video"
                  className="d-none"
                  onChange={handleSelectVideo}
                />
                <label htmlFor="duration" className="small mt-3">
                  Preview Duration:
                </label>
                <InputMask
                  kind="datetime"
                  options={{
                    format: 'HH:mm:ss',
                  }}
                  id="duration"
                  name="duration"
                  onChange={handleChangeDuration}
                  onBlur={handleBlurDuration}
                  value={videoDuration}
                  placeholder="00:00:00"
                  className="py-3"
                />
              </div>
              <hr className="d-none d-xl-block hr-gray my-5" />
              <div className="col-lg-4 col-xl-12 pl-lg-5 px-xl-0 mt-5 mt-lg-0">
                <div>
                  <label htmlFor="price" className="small">
                    Price
                  </label>
                  <InputMask
                    kind="money"
                    options={{
                      unit: '$',
                      delimiter: ',',
                      separator: '.',
                    }}
                    id="price"
                    name="price"
                    value={course.price}
                    className="py-3"
                  />
                </div>
                <div className="mt-1">
                  <label className="small">Show Promotion Price</label>
                  <div className="d-flex text-center">
                    <label
                      className={`btn w-100 mx-1 btn-radio ${
                        showPromotionPrice === 'yes' ? 'btn-grey' : 'btn-dark'
                      }`}
                      htmlFor="show_promotion_price_yes"
                    >
                      <span className="d-block py-2">YES</span>
                      <Input
                        type="radio"
                        onChange={changeShowPromotionPrice}
                        name="show_promotion_price"
                        id="show_promotion_price_yes"
                        className="d-none"
                        value="yes"
                        checked={showPromotionPrice === 'yes'}
                      />
                    </label>
                    <label
                      className={`btn w-100 mx-1 btn-radio ${
                        showPromotionPrice === 'no' ? 'btn-grey' : 'btn-dark'
                      }`}
                      htmlFor="show_promotion_price_no"
                    >
                      <span className="d-block py-2">NO</span>
                      <Input
                        type="radio"
                        onChange={changeShowPromotionPrice}
                        name="show_promotion_price"
                        id="show_promotion_price_no"
                        className="d-none"
                        value="no"
                        checked={showPromotionPrice === 'no'}
                      />
                    </label>
                  </div>
                </div>
                {showPromotionPrice === 'yes' && (
                  <div className="mt-1">
                    <label htmlFor="promotion_price" className="small">
                      Course Promotion Price
                    </label>
                    <InputMask
                      kind="money"
                      options={{
                        unit: '$',
                        delimiter: ',',
                        separator: '.',
                      }}
                      id="promotion_price"
                      name="promotion_price"
                      value={course.promotion_price}
                      className="py-3"
                    />
                  </div>
                )}
              </div>
            </VideoInfo>
          </div>
          <div className="col-xl-8 col-xxl-9">
            <CourseInfo>
              <h3 className="h5">Update Course</h3>
              <div className="row">
                <div className="col-sm-6">
                  <div className="mt-3">
                    <label htmlFor="title" className="small">
                      Title
                    </label>
                    <Input
                      id="title"
                      name="title"
                      className="py-3"
                      onChange={handleChangeTitle}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="mt-3">
                    <label htmlFor="subtitle" className="small">
                      Subtitle
                    </label>
                    <Input id="subtitle" className="py-3" name="subtitle" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="mt-3">
                    <label htmlFor="presented_by" className="small">
                      Presented by
                    </label>
                    <Input
                      id="presented_by"
                      className="py-3"
                      name="presented_by"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="mt-3">
                    <label htmlFor="categories">Categories</label>
                    <InputCategory
                      type="courses"
                      onSelect={handleSelectCategories}
                      onRemove={handleRemoveCategory}
                      className="py-1"
                      valuesSelected={categoriesData}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="mt-3">
                    <label htmlFor="description" className="small">
                      Description
                    </label>
                    <Textarea
                      id="description"
                      name="description"
                      className="p-3"
                      rows={10}
                    />
                  </div>
                </div>
              </div>
            </CourseInfo>
            <CourseInfo className="mt-4">
              <h3 className="h5">SEO</h3>
              <div className="row">
                <div className="col-lg-6">
                  <div className="mt-3">
                    <label htmlFor="slug" className="small">
                      URL
                    </label>
                    <div className="d-flex align-items-center link-preview p-relative">
                      <span className="mb-0 before-slug py-3">/course/</span>
                      <Input
                        id="slug"
                        name="slug"
                        value={url}
                        onChange={handleChangeSlug}
                        className="py-3"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mt-3">
                    <label htmlFor="page_title" className="small">
                      Page Title
                    </label>
                    <Input id="page_title" name="page_title" className="py-3" />
                  </div>
                </div>
                <div className="col-12">
                  <div className="mt-3">
                    <label htmlFor="meta_description" className="small">
                      Meta Description
                    </label>
                    <Input
                      id="meta_description"
                      name="meta_description"
                      className="py-3"
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-5 align-items-end justify-content-end">
                <div className="col-lg-4">
                  <button type="submit" className="btn-grey py-3 w-100">
                    <span className="d-block font-weight-bold">Save</span>
                  </button>
                </div>
              </div>
            </CourseInfo>
          </div>
        </Form>
      </div>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <p className="font-weight-bold text-white mt-3">{status}</p>
        </div>
      )}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="ml-auto">Delete course</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-0 text-center">
            Are you sure you want to delete the course
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-around">
          <button
            type="button"
            onClick={handleClose}
            className="w-25 btn-grey px-3 py-2"
          >
            NO
          </button>
          <button
            type="button"
            onClick={handleDelete}
            className="w-25 btn-grey px-3 py-2"
          >
            YES
          </button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default CoursesUpdate;
