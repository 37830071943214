import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

interface Img {
  src: string;
}

export const Container = styled.div`
  .min-width {
    min-width: 600px;
  }
  .border-gray {
    border-color: #8c8c8c;
  }

  .hr-gray {
    border-color: #242526;
  }

  .step-lesson {
    span {
      color: #8c8c8c;

      :hover {
        color: rgba(32, 32, 32, 1) !important;
        text-decoration: none;
      }
    }
    text-decoration: none;
  }
  form {
    background-color: rgba(32, 32, 32, 1);
    border-radius: 20px;
  }
  .delete {
    background-color: transparent;
    border: none;
  }
  .btn-light-grey {
    background-color: rgba(203, 204, 206, 1);
    border: none;
    color: rgba(110, 110, 110, 1);
    border-radius: 15px;
  }
`;

export const VideoInfo = styled.div`
  background: #202020;
  border-radius: 20px;
  padding: 20px;

  .bg-gray {
    background: #242526;
    border-radius: 20px;
    overflow: hidden;

    p {
      color: #565656;
    }
  }

  .remove-video {
    top: 0;
    right: 5px;
    z-index: 999;
    background-color: transparent;
    border: none;
  }

  .short-tilte {
    border: none;
    border-bottom: 1px solid rgba(51, 51, 51, 1);
    border-radius: 0;
  }

  .border-gray {
    border-color: #8c8c8c;
  }

  label {
    color: #828282;
  }

  .btn-radio {
    color: #cbccce;

    :hover {
      color: #cbccce;
    }
  }

  @media (min-width: 769px) and (max-width: 1199px) {
    .bd-right {
      border-right: solid 1px #333;
    }
  }
`;

export const CourseInfo = styled.div`
  background: #202020;
  border-radius: 20px;
  padding: 20px;

  .bg-gray {
    background: #242526;
    border-radius: 20px;
    overflow: hidden;

    p {
      color: #565656;
    }
  }

  label {
    color: #828282;
  }

  .bio-photo {
    width: 100%;
    border-radius: 20px;
  }

  .link-preview {
    span {
      background: #242526;
      border-radius: 10px;
      border: 1.5px solid #6e6e6e;
      padding: 5px 20px;
      color: #cbccce;
      margin-right: -15px;
      z-index: 1;
    }

    input {
      padding-left: 15px;
    }
  }
`;

export const Img = styled.div<Img>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  height: 184px;
`;

export const VideoPreview = styled.div`
  border-radius: 20px;
  overflow: hidden;
`;

export const Modal = styled(ModalComponent)``;
